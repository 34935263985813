<template>
  <el-card :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: 0 }" :class="background" class="card">
    <el-row v-if="title || tab || $slots['toolbar']" class="card__header">
      <el-col :class="'bg-' + titleBackground" class="card__header__content">
        <div v-if="title" class="title">
          <span :class="titleBackground ? 'text-white' : ''" class="font-size-lg font-weight-bold">{{ title }}</span>
          <span :class="titleBackground ? 'text-white' : 'text-muted'" class="font-size-xs font-weight-bold">{{ subtitle }}</span>
        </div>
        <div v-if="tab" :style="{ flexGrow: title ? '' : 1 }" class="tab">
          <ul class="nav">
            <li v-for="tb in tab" :key="tb.id" class="nav__item"><a :class="{ 'text-primary bg-light-primary': activeTab === tb.id, 'text-muted': activeTab !== tb.id }" class="nav__link font-weight-bolder text-hover-primary" @click="tabClick(tb.id)"><i v-if="tb.icon" :class="tb.icon"></i> {{ tb.label }}</a></li>
          </ul>
        </div>
        <div class="toolbar">
          <div v-if="$slots['toolbar']" class="toolbar__content">
            <slot name="toolbar"></slot>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :style="bodyStyle" class="card__body">
      <el-col class="card__body__content">
        <div v-if="$slots['header']" class="header">
          <slot name="header"></slot>
        </div>
        <div class="content">
          <slot></slot>
        </div>
        <div v-if="$slots['footer']"  class="footer">
          <slot name="footer">
          </slot>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    &__content {
      display: flex;
      min-height: 55px;
      align-items: center;
      padding: 20px 25px 0 25px;

      .title {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      .tab {
        display: flex;
        align-items: center;
        .nav {
          display: flex;
          flex-wrap: wrap;
          padding-left: 0;
          margin: 0;
          list-style: none;

          ul {
            margin-top: 0;
          }

          &__link {
            padding: 9.75px 19.5px;
            font-size: 13px;
            display: flex;
            align-items: center;
            border-radius: 5.5px;
            transition: .2s all;
            cursor: pointer;

            i {
              margin-right: 5px;
              font-size: 16px !important;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  &__body {
    flex: 1;
    padding: 20px 25px 25px 25px;

    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .header {
        margin-bottom: 20px;
      }

      .content {
        flex: 1;
        height: 0;
      }

      .footer {
        padding-top: 10px;
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    tab: Array,
    activeTab: Number,
    titleBackground: String,
    bodyStyle: String,
    background: String
  },
  methods: {
    tabClick (id) {
      if (this.activeTab === id) return
      this.$emit('tab-click', id)
    }
  }
}
</script>
