<template>
  <div class="content">
    <el-row class="content__header" v-if="title || $slots['title'] || $slots['toolbar']">
      <el-col class="content__header__content">
        <div class="title" v-if="title">
          <span class="font-weight-bold font-size-lg">{{ title }}</span>
          <span class="font-weight-bold font-size-xs text-muted">{{ subtitle }}</span>
        </div>
        <div class="title" v-else-if="!title && $slots['title']">
          <slot name="title"></slot>
        </div>
        <div class="toolbar" v-if="$slots['toolbar']">
          <slot name="toolbar"></slot>
        </div>
      </el-col>
    </el-row>
    <el-row class="content__body" :class="{ 'overflow': overflow }">
      <el-col class="content__body__content">
        <div class="header" v-if="$slots['header']">
          <slot name="header"></slot>
        </div>
        <div class="content">
          <slot></slot>
        </div>
        <div class="footer"  v-if="$slots['footer']">
          <slot name="footer">
          </slot>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/components/_variables.bootstrap.scss';

.content {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    &__content {
      display: flex;
      min-height: 55px;
      align-items: center;
      padding-bottom: 10px;

      .title {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }
  }

  &__body {
    flex: 1;

    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .header {
        margin-bottom: 20px;
      }

      .content {
        flex: 1;
        height: 0;
      }

      .footer {
        padding-top: 10px;
      }
    }
  }
}

.overflow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .content__body__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
    width: 100%;
    height: 0px;

    .content {
      flex: 0;
      height: auto;
    }
  }
}
</style>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    overflow: Boolean
  }
}
</script>
