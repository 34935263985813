<template>
  <el-row class="breadcrumb">
    <el-col class="breadcrumb__content">
      <div class="breadcrumb-item">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <el-breadcrumb-item :key="i">{{ breadcrumb.title }} <span v-if="breadcrumb.status" class="color" :class="breadcrumb.color" style="margin-left: 5px;border-radius: 5px; padding: 3px 5px; font-size: 12px">{{ breadcrumb.status }}</span></el-breadcrumb-item>
          </template>
        </el-breadcrumb>
      </div>
      <div class="toolbar">
      </div>
    </el-col>
  </el-row>
</template>

<style lang="scss" scoped>
.breadcrumb {
  padding: 0 20px;
  background: #fff;
  border-top: 1px solid #EBEDF3;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  &__content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .breadcrumb-item {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: #606266;
    }
  }
}
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'breadcrumbs',
      'pageTitle'
    ])
  }
}
</script>
