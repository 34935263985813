<template>
  <BaseContainer>
    <BaseCard>
      <el-form ref="project" :model="project" :rules="rules" label-width="110px">
        <BaseContent v-loading="loading" overflow>
          <template #title>
            <div class="gf-title">
              <div v-if="selectedCompany.name" class="supplier-name">
                <p>
                  {{ selectedCompany.name }}
                  <i class="el-icon-close" @click="removeSelectedCompany"></i>
                </p>
              </div>
              <div v-else class="company-select">
                <el-form-item label-width="0" prop="company_id">
                  <el-select v-model="project.company_id" :remote-method="searchCompany" filterable
                    placeholder="Select Company" remote size="medium" @change="changeSelectedCompany">
                    <el-option v-for="company in companies" :key="company.id" :label="company.name" :value="company.id" />
                  </el-select>
                </el-form-item>
                <span> or </span>
                <el-button type="text" @click="addCompanyModalVisible = true">Create a new company</el-button>
              </div>
            </div>
          </template>
          <template #toolbar>
            <div class="gf-toolbar">
              <div class="number">
                <p>Code: {{ project.code || '####' }}</p>
              </div>
            </div>
          </template>
          <template #header>
            <el-row :gutter="20">
              <el-col :lg="8" :md="12" :sm="24">
                <el-form-item label="Code" prop="code">
                  <el-input v-model="project.code" size="small" style="width: 100%;"></el-input>
                </el-form-item>
                <el-form-item label="Name" prop="name">
                  <el-input v-model="project.name" size="small" style="width: 100%;"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12" :sm="24">
                <el-form-item label="Ship To" label-width="75px" prop="ship_to_id">
                  <el-select v-model="project.ship_to_id" placeholder="Select" size="small" style="width: 100%;">
                    <el-option v-for="address in companyAddresses" :key="address.id"
                      :label="`${address.address_line_1} ${address.address_line_2}, ${address.city}, ${address.zip_code}`"
                      :value="address.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Contact No" prop="code">
                  <el-input v-model="project.code" size="small" style="width: 100%;"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12" :sm="24">
                <el-form-item label="Totals are">
                  <el-select v-model="project.is_inclusive" placeholder="Select" size="small" style="width: 100%;">
                    <el-option :value="0" label="Tax Exclusive"></el-option>
                    <el-option :value="1" label="Tax Inclusive"></el-option>
                    <el-option :value="2" label="No Invoice"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <div class="table">
            <BaseTable :data="project.items" :scrollable="false">
              <el-table-column label="Item No" width="70">
                <template slot-scope="slot">
                  <div class="roman-no">
                    <span>
                      <b>
                        {{ $IntToRoman(slot.$index + 1) }}
                      </b>
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Description" min-width="160">
                <template slot-scope="slot">
                  <div>
                    <el-form-item :prop="'items.' + slot.$index + '.description'"
                      :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                      <el-input v-model="slot.row.description" :rows="2" placeholder="" type="textarea"
                        :autosize="{ minRows: 2, maxRows: 10 }" />
                    </el-form-item>
                  </div>
                  <div>
                    <BaseTable :data="slot.row.subitems" :scrollable="false" :cell-custom-class="subItemCellClass">
                      <el-table-column label="Code" prop="code" width="100" class="subitem-column">
                        <template slot-scope="sslot">
                          <el-input v-model="sslot.row.code" size="small" style="width: 100%;" />
                        </template>
                      </el-table-column>
                      <el-table-column label="Width" prop="width" min-width="100">
                        <template slot-scope="sslot">
                          <el-input v-model="sslot.row.width" size="small" style="width: 100%;" />
                        </template>
                      </el-table-column>
                      <el-table-column label="Height" prop="height" min-width="100">
                        <template slot-scope="sslot">
                          <el-input v-model="sslot.row.height" size="small" style="width: 100%;" />
                        </template>
                      </el-table-column>
                      <el-table-column label="Quantity" prop="quantity" min-width="110">
                        <template slot-scope="sslot">
                          <el-input v-model="sslot.row.height" size="small" style="width: 100%;" />
                        </template>
                      </el-table-column>
                      <el-table-column v-for="(option, index) in slot.row.options" v-bind:key="index" min-width="130">
                        <template slot="header">
                          <div style="display:flex;">
                            <el-input v-model="option.label" size="mini" placeholder="Input Option Name"
                              style="width: 100%;" />
                            <el-link v-if="index > 0" :underline="false" class="ml-2" type="danger"
                              @click="deleteOption(slot.$index, index)"><i class="el-icon-close" /></el-link>
                          </div>
                        </template>
                        <template slot-scope="sslot">
                          <el-input v-model="sslot.row.height" size="small" style="width: 100%;" />
                        </template>
                      </el-table-column>
                      <el-table-column label="" width="50">
                        <template slot="header">
                          <el-link :underline="false" type="primary" @click="addOption(slot.$index)"><i
                              class="el-icon-plus" /></el-link>
                        </template>
                        <template slot-scope="sslot">
                          <el-link :underline="false" type="danger" @click="deleteSubItem(slot.$index, sslot.$index)"><i
                              class="el-icon-close" /></el-link>
                        </template>
                      </el-table-column>
                    </BaseTable>
                  </div>
                  <div class="add-item mt-2">
                    <BaseButton light size="sm" style="width: 100%" type="primary" @click="addSubItem(slot.$index)">+ Add
                      Sub-item</BaseButton>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="" width="50">
                <template slot-scope="slot">
                  <el-link :underline="false" type="danger" @click="deleteItem(slot.$index)"><i
                      class="el-icon-close" /></el-link>
                </template>
              </el-table-column>
            </BaseTable>
          </div>
          <div class="add-item">
            <gf-button light size="sm" style="width: 100%" type="primary" @click="addItem">+ Add another item</gf-button>
          </div>
          <div class="footer mt-4">
            <div class="note">
            </div>
            <div class="gf-summary">
              <ul class="summary">
                <li class="summary__item">
                  <span class="text">Total Units</span>
                  <span class="value">{{ $NumberFormat(totalUnits) }}</span>
                </li>
                <li v-if="project.is_inclusive !== 2" class="summary__item">
                  <span class="text">Subtotal</span>
                  <span class="value">{{ $NumberFormat(subtotal) }}</span>
                </li>
                <div v-if="project.is_inclusive !== 2">
                  <li v-for="(vat, index) in vats" :key="index" class="summary__item">
                    <span class="text">{{ (project.is_inclusive) ? 'Including' : 'Plus' }} VAT ({{ vat.rate }}%)</span>
                    <span class="value">{{ $NumberFormat(vat.value) }}</span>
                  </li>
                </div>
                <li class="summary__item">
                  <span class="text">Total</span>
                  <span class="value">{{ $NumberFormat(total) }}</span>
                </li>
              </ul>
            </div>
          </div>
          <template #footer>
            <div class="text-right">
              <BaseButton class="mr-3" size="sm" type="primary" @click="create('Active')">Submit</BaseButton>
              <BaseButton class="mr-3" size="sm" type="warning" @click="create('Draft')">Save As Quote</BaseButton>
              <BaseButton size="sm" type="secondary" @click="cancel">Cancel</BaseButton>
            </div>
          </template>
        </BaseContent>
      </el-form>
    </BaseCard>
  </BaseContainer>
</template>

<style lang="scss" scoped>
.content {
  .el-form-item {
    width: auto;
    margin: 0;
  }
}

.el-form {
  height: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;

  .note {
    width: 500px;
  }
}

.multiple-column {
  display: flex;

  .flex-grow {
    flex-grow: 1;
  }
}

.roman-no {
  display: flex;
  justify-content: center;
}
</style>

<script>
import BaseContainer from '@/component-v2/BaseContainer'
import BaseContent from '@/component-v2/BaseContent'
import BaseCard from '@/component-v2/BaseCard'
import BaseTable from '@/component-v2/BaseTable'
import BaseButton from '@/component-v2/BaseButton'

import CompanyService from '@/services/v1/Company'
import CompanyAddressService from '@/services/v1/CompanyAddress'
import ProjectService from '@/services/v1/ProjectService'

import { PROJECT_STATUS } from '../../../constants/status'

export default {
  components: { BaseButton, BaseCard, BaseContent, BaseContainer, BaseTable },
  props: {
    loaded: Boolean,
    updateChild: Boolean
  },
  data() {
    return {
      projectId: this.$route.params.id,

      title: '',
      subtitle: '',
      mode: this.$route.meta.mode,
      loading: false,

      project: {
        company_id: null,
        name: '',
        code: '',
        description: '',
        bill_to_id: null,
        ship_to_id: null,
        is_inclusive: 2,
        items: []
      },
      rules: {
        company_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        bill_to_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        ship_to_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        code: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        description: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
      selectedCompany: {
        name: null
      },

      companies: [],
      companyAddresses: [],

      companyFilter: {
        'cus.type': {
          '!=': 3
        }
      }
    }
  },
  computed: {
    totalUnits() {
      let total = 0

      for (let i = 0; i < this.project.items.length; i++) {
        const e = this.project.items[i]
        total += parseFloat(e.quantity)
      }

      return total
    },
    subtotal() {
      let total = 0

      if (this.project.is_inclusive === 0) {
        for (let i = 0; i < this.project.items.length; i++) {
          const e = this.project.items[i]
          const sub = parseFloat(e.quantity) * parseFloat(e.cost)
          const discount = sub * parseFloat(e.discount / 100)
          total += sub - discount
        }
      } else {
        for (let i = 0; i < this.project.items.length; i++) {
          const e = this.project.items[i]
          const sub = parseFloat(e.quantity) * parseFloat(e.cost)
          const discount = sub * parseFloat(e.discount / 100)
          const discounted = sub - discount
          const tax = parseFloat(e.tax) / 100
          total += discounted - (discounted * tax)
        }
      }

      return total
    },
    vats() {
      const vt = []

      if (this.project.items.length > 0) {
        for (let i = 0; i < this.project.items.length; i++) {
          const e = this.project.items[i]
          const found = vt.find(vat => vat.rate === e.tax)

          if (found) {
            found.value += (e.tax / 100) * (parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100)))
          } else {
            vt.push({
              rate: e.tax,
              value: (e.tax / 100) * (parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100)))
            })
          }
        }
      }

      return vt
    },
    total() {
      let total = 0

      if (this.project.items.length > 0) {
        for (let i = 0; i < this.project.items.length; i++) {
          const e = this.project.items[i]
          total += parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100))
        }
      }

      if (!this.project.is_inclusive) {
        const vats = this.vats

        for (let i = 0; i < vats.length; i++) {
          const vat = vats[i]
          total += parseFloat(vat.value)
        }
      }

      return total
    }
  },
  watch: {
    updateChild: function () {
      if (this.updateChild) {
        this.$emit('update:updateChild', false)
        this.$router.push({ name: 'project-summary', params: { id: this.$route.params.id } })
      }
    }
  },
  methods: {
    subItemCellClass() {
      return 'tableInsideCell'
    },
    addItem() {
      this.project.items.push({
        description: '',
        subitems: [
          {
            code: '',
            width: 0,
            height: 0
          }
        ],
        options: [
          {
            label: ''
          }
        ]
      })
    },
    deleteItem(index) {
      this.project.items.splice(index, 1)
    },
    addOption(index) {
      this.project.items[index].options.push({
        label: ''
      })
    },
    deleteOption(index, row) {
      console.log(index, row)
      this.project.items[index].options.splice(row, 1)
    },
    addSubItem(index) {
      this.project.items[index].subitems.push({
        code: '',
        width: 0,
        height: 0,
        options: [
          {
            label: 'Option 1',
            value: 0
          }
        ]
      })
    },
    deleteSubItem(index, row) {
      this.project.items[index].subitems.splice(row, 1)
    },
    cancel() {
      this.$router.push({ name: 'project-list' })
    },
    create(status) {
      this.$refs.project.validate()
        .then(async () => {
          try {
            this.loading = true

            if (this.project.items.length === 0) {
              this.$notify.error({
                title: 'Error',
                message: 'Project must contain at least 1 item'
              })
              return
            }

            const project = this.$_.cloneDeep(this.project)
            project.status = PROJECT_STATUS[status]
            project.units = this.totalUnits
            project.subtotal = this.subtotal
            project.vats = this.vats
            project.total = this.total

            let id = this.projectId
            if (this.mode === 'create') {
              const projectService = new ProjectService()
              const result = await projectService.create(project)
              this.$message.success(`Project Code: ${this.project.code} successfully created`)
              id = result.data.id
            }

            this.$emit('update:loaded', false)
            this.$router.push({ name: 'project-view', params: { id } })
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => { })
    },
    async changeSelectedCompany(id) {
      try {
        const company = this.companies.find(cus => cus.id === id)
        this.selectedCompany.name = company.name
        this.listAddresses(this.project.company_id)
      } catch (error) {
        this.$Error(error)
      }
    },
    removeSelectedCompany() {
      this.selectedCompany.name = null
      this.project.company_id = null
      this.project.bill_to_id = null
      this.project.ship_to_id = null
      this.searchCompany('')
    },
    async searchCompany(query) {
      if (query !== '') {
        this.companyFilter.q = {
          like: query
        }
      } else delete this.companyFilter.q

      await this.listCompanies()
    },
    async listCompanies() {
      try {
        const cmService = new CompanyService()
        const result = await cmService.list(null, { cus: ['id', 'name'] }, this.companyFilter)
        this.companies = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async listAddresses(id) {
      try {
        const caService = new CompanyAddressService(id)
        const result = await caService.list()
        this.companyAddresses = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  async mounted() {
    this.loading = true
    this.mode = this.$route.meta.mode

    await this.listCompanies()

    if (this.mode === 'create') {
      this.title = 'New Project'
      this.subtitle = 'Input Project Details'

      await this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Project' },
        { title: 'Create' }
      ])

      this.loading = false
    }
  }
}
</script>
